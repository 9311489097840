import { DialogUtility } from "@syncfusion/ej2-popups";
import * as R from "ramda";
import {
  PropsWithChildren,
  Reducer,
  useEffect,
  useReducer,
  useRef,
} from "react";
import { FormRenderProps } from "react-final-form";
import RAFForm from "../../../RAFComponents/Inputs/RAFForm";
import RAFButtonComponent from "../../../RAFComponents/Navigation/RAFButtonComponent";
import RAFDeletedRecordState from "../../../RAFComponents/Navigation/RAFDeletedRecordState";
import RAFAttributeRelatedListProvider, {
  RAFAttributesContext,
} from "../../../RAFComponents/Providers/RAFAttributeRelatedListProvider";
import {
  showSuccessToast,
  showWarningToast,
} from "../../../RAFComponents/Utility/RAFToastComponent";
import {
  DeleteRecord,
  RetrieveRecord,
  getSessionStorage,
  hideProgress,
  setSessionStorage,
  showProgress,
} from "../../../RAFComponents/helpers/AppHelper";
import {
  IDialogProps,
  IsNullOrWhiteSpace,
  RAFFormComponentProps,
  getSaveRequest,
  isNotNullAndUndefined,
} from "../../../RAFComponents/helpers/utils";
import { QueryAttributeJM } from "../../../RAFComponents/models/Common/QueryAttributeJM";
import { RAFViewRow } from "../../../RAFComponents/models/Common/RAFViewRow";
import * as repositoryActions from "../../../RAFComponents/store/actions/repositoryActions";
import { EntityRow } from "../../../RAFMaster/RMModules/Entity/EntityRow";
import { EvaluateScore } from "../../../RAFMaster/helpers/RMutils";
import { CareEsioEntity } from "../../../constants/CareESIO/CareEsioConstants";
import {
  Constants,
  ContentType,
  RAFButtonConstant,
  RAFLayout,
  StorageKey,
} from "../../../constants/Common/Constants";
import RAFEntityProvider, {
  RAFEntityContext,
} from "../../Common/Providers/RAFEntityProvider";
import RAFObjectContextProvider, {
  RAFObjectContext,
} from "../../Common/Providers/RAFObjectContextProvider";
import EditContent from "../../Common/Update/EditContent";

interface IProps {
  moduleName?: string;
  onSave?: (entityId?: string, objectName?: string) => void;
  onClose?: () => void;
  onDelete?: () => void;
  objectUID?: string;
  isActive?: boolean;
}

interface IState {
  isActive?: boolean;
  cqiRegisterRow?: any;
}

function UpdateCQIRegister({
  objectUID,
  isActive,
  ...props
}: PropsWithChildren<IProps & RAFFormComponentProps & IDialogProps>) {
  let rafForm: FormRenderProps | null;
  let submitButtonRef = useRef(null);
  let deleteDialog: any;
  // let objectGUID = useRecoilValue(atomEditingRecordId(moduleName));
  // objectUID = isNotNullAndUndefined(objectGUID) ? objectGUID : objectUID;

  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      isActive: isNotNullAndUndefined(isActive) ? isActive : false,
      cqiRegisterRow: null,
    }
  );

  useEffect(() => {
    let progressDiv = showProgress(
      `#dlg_update_${CareEsioEntity.CareRecipientCQIRegister.EntityName}`
    );
    RetrieveRecord(
      objectUID,
      CareEsioEntity.CareRecipientCQIRegister.EntityName
    ).then((cqiRegisterRow) => {
      hideProgress(progressDiv);
      setState({
        isActive: true,
        cqiRegisterRow,
      });
    });
  }, [objectUID]);

  let { cqiRegisterRow } = state;

  let moduleName =
    isNotNullAndUndefined(cqiRegisterRow) &&
      isNotNullAndUndefined(cqiRegisterRow.Register)
      ? cqiRegisterRow.Register
      : CareEsioEntity.CareRecipientCQIRegister.EntityName;

  const onSubmitObject = (
    values?: any,
    entity?: EntityRow,
    queryAttributes?: QueryAttributeJM[]
  ) => {
    let updatedValues = R.clone(values);
    if (
      isNotNullAndUndefined(entity) &&
      isNotNullAndUndefined(entity.EntitySettings) &&
      isNotNullAndUndefined(entity.EntitySettings.EnableScore) &&
      entity.EntitySettings.EnableScore === true
    ) {
      updatedValues = EvaluateScore(values, queryAttributes);
    }
    let progressDiv = showProgress(`#raf_dlg_Outer_Div_${moduleName}`);
    let saveRequestData = getSaveRequest(updatedValues, updatedValues.UID);
    saveRequestData.EntityName = isNotNullAndUndefined(updatedValues.Register)
      ? updatedValues.Register
      : CareEsioEntity.CareRecipientCQIRegister.EntityName;

    repositoryActions
      .postDataAndGetResponse(
        "Datalist/SaveSubForms",
        saveRequestData,
        null,
        ContentType.applicationJson
      )
      .then((response) => {
        hideProgress(progressDiv);
        if (
          isNotNullAndUndefined(response) &&
          isNotNullAndUndefined(response.data) &&
          isNotNullAndUndefined(response.data.EntityId)
        ) {
          showSuccessToast("Saved successfully");

          let allViews: RAFViewRow[] = JSON.parse(
            getSessionStorage(StorageKey.allViews_modulename + moduleName, true)
          );
          let recentlyCreatedView: RAFViewRow =
            allViews && allViews.find((x) => x.ViewName === "Recently_Created"); //recently_created;
          if (isNotNullAndUndefined(recentlyCreatedView)) {
            setSessionStorage(
              StorageKey.recentViewId_modulename + moduleName,
              true,
              JSON.stringify({
                viewId: recentlyCreatedView.UID,
                viewName: recentlyCreatedView.DisplayName,
              })
            );
          }
          if (props.onSave)
            props.onSave(response.data.EntityId, response.data.ObjectName);
        } else {
          showWarningToast("Sorry something went wrong !");
        }
      })
      .catch((error) => error);
  };

  const enableSubmitButton = (g: FormRenderProps | null) => {
    if (g) {
      if (
        isNotNullAndUndefined(submitButtonRef) &&
        isNotNullAndUndefined(submitButtonRef.current) &&
        isNotNullAndUndefined(submitButtonRef.current.toggleBtnPermission)
      ) {
        submitButtonRef.current.toggleBtnPermission(g.submitting);
      }
    }
  };

  //delete item start
  const onClickDeleteCareRecipientCQIRegister = () => {
    deleteDialog = DialogUtility.confirm({
      animationSettings: { effect: "Fade" },
      cancelButton: { text: "No" },
      closeOnEscape: false,
      content: "Are you sure want to delete?",
      okButton: { text: "Yes", click: deleteRecord.bind(this) },
      title: `Delete ${CareEsioEntity.CareRecipientCQIRegister.DisplayName}`,
      position: { X: "center", Y: "center" },
      cssClass: `alert-dialog deleteDialog`,
    });
  };

  async function deleteRecord() {
    let progressDiv = showProgress(".deleteDialog.e-dialog");
    let { cqiRegisterRow } = state;

    let isDeleted = await DeleteRecord(
      objectUID,
      isNotNullAndUndefined(cqiRegisterRow.CareRecipientCQIRegister)
        ? cqiRegisterRow.CareRecipientCQIRegister
        : CareEsioEntity.CareRecipientCQIRegister.EntityName
    );

    if (isDeleted) {
      hideProgress(progressDiv);
      deleteDialog.hide();
      if (isNotNullAndUndefined(props.onDelete)) {
        props.onDelete();
      }
    } else {
      hideProgress(progressDiv);
      deleteDialog.hide();
      showWarningToast("Sorry something went wrong !");
    }
  }
  //delete item end

  if (
    isNotNullAndUndefined(objectUID) &&
    !IsNullOrWhiteSpace(objectUID) &&
    state.isActive
  ) {
    if (moduleName === CareEsioEntity.CareRecipientCQIRegister.EntityName) {
      return (
        <div
          className="h-100"
          key={`${objectUID}_${moduleName}`}
          id={`raf_dlg_Outer_Div_${moduleName}`}
        >
          <RAFForm
            initialValues={cqiRegisterRow}
            formRef={(g) => {
              enableSubmitButton(g);
              return (rafForm = g);
            }}
            layout={RAFLayout.TwoColumnLayout}
            onSubmit={(values) => onSubmitObject(values, null, null)}
            primaryKey={objectUID}
            //decorators={[accountDecorators]}
            //convertBeforeSave={ConvertAccountRowToAccountDto}
            className="h-100"
          >
            <div className="e-dlg-content-outer">
              <div className="e-dlg-body-content">
                <div>
                  <RAFEntityProvider moduleName={moduleName}>
                    <EditContent
                      moduleName={moduleName}
                      progressDivId={`#raf_dlg_Outer_Div_${moduleName}`}
                    />
                  </RAFEntityProvider>
                </div>
              </div>
              <div className="e-dlg-footerContent">
                <div className="w-100">
                  <div className="row gx-2">
                    {isNotNullAndUndefined(props.onDelete) ? (
                      <div className="col-auto">
                        <RAFButtonComponent
                          action={RAFButtonConstant.Delete}
                          onClick={() =>
                            onClickDeleteCareRecipientCQIRegister()
                          }
                          idString="DeleteContent"
                          className="e-danger e-outline"
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="col-auto ms-auto">
                      <RAFButtonComponent
                        ref={submitButtonRef}
                        isPrimary
                        action={RAFButtonConstant.Save}
                        onClick={() => rafForm && rafForm.form.submit()}
                        idString="EditContent"
                        disabled={rafForm && rafForm.submitting}
                      />
                    </div>
                    <div className="col-auto">
                      <RAFButtonComponent
                        action={RAFButtonConstant.Cancel}
                        onClick={props.onClose}
                        idString="EditContent"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </RAFForm>
        </div>
      );
    } else {
      return (
        <div
          className="h-100"
          key={`${objectUID}_${moduleName}`}
          id={`raf_dlg_Outer_Div_${moduleName}`}
        >
          <RAFEntityProvider moduleName={moduleName}>
            <RAFAttributeRelatedListProvider moduleName={moduleName}>
              <RAFAttributesContext.Consumer>
                {({ queryAttributes }) => {
                  return (
                    <RAFEntityContext.Consumer>
                      {({ entity }) => {
                        return (
                          <RAFObjectContextProvider
                            moduleName={moduleName}
                            objectId={objectUID}
                            progressDivId={`#raf_dlg_Outer_Div_${moduleName}`}
                          >
                            <RAFObjectContext.Consumer>
                              {({
                                /*objectId, */ rafObject,
                                error,
                                saveObject,
                              }) => {
                                if (
                                  isActive &&
                                  isNotNullAndUndefined(rafObject) &&
                                  !IsNullOrWhiteSpace(rafObject.UID)
                                ) {
                                  return (
                                    <RAFForm
                                      initialValues={rafObject}
                                      formRef={(g) => {
                                        enableSubmitButton(g);
                                        return (rafForm = g);
                                      }}
                                      layout={RAFLayout.TwoColumnLayout}
                                      onSubmit={(values) =>
                                        onSubmitObject(
                                          values,
                                          entity,
                                          queryAttributes
                                        )
                                      }
                                      primaryKey={rafObject.UID}
                                      //decorators={[accountDecorators]}
                                      //convertBeforeSave={ConvertAccountRowToAccountDto}
                                      className="h-100"
                                    >
                                      <div className="e-dlg-content-outer">
                                        <div className="e-dlg-body-content">
                                          <div>
                                            <RAFEntityProvider
                                              moduleName={
                                                CareEsioEntity
                                                  .CareRecipientCQIRegister
                                                  .EntityName
                                              }
                                            >
                                              <EditContent
                                                moduleName={
                                                  CareEsioEntity
                                                    .CareRecipientCQIRegister
                                                    .EntityName
                                                }
                                                progressDivId={`#raf_dlg_Outer_Div_${moduleName}`}
                                              />
                                            </RAFEntityProvider>
                                          </div>
                                          <div className="mt-4 mx-1">
                                            <EditContent
                                              moduleName={moduleName}
                                              progressDivId={`#raf_dlg_Outer_Div_${moduleName}`}
                                            />
                                          </div>
                                        </div>
                                        <div className="e-dlg-footerContent">
                                          <div className="w-100">
                                            <div className="row gx-2">
                                              {isNotNullAndUndefined(
                                                props.onDelete
                                              ) ? (
                                                <div className="col-auto">
                                                  <RAFButtonComponent
                                                    action={
                                                      RAFButtonConstant.Delete
                                                    }
                                                    onClick={() =>
                                                      onClickDeleteCareRecipientCQIRegister()
                                                    }
                                                    idString="DeleteContent"
                                                    className="e-danger e-outline"
                                                  />
                                                </div>
                                              ) : (
                                                ""
                                              )}
                                              <div className="col-auto ms-auto">
                                                <RAFButtonComponent
                                                  ref={submitButtonRef}
                                                  isPrimary
                                                  action={
                                                    RAFButtonConstant.Save
                                                  }
                                                  onClick={() =>
                                                    rafForm &&
                                                    rafForm.form.submit()
                                                  }
                                                  idString="EditContent"
                                                  disabled={
                                                    rafForm &&
                                                    rafForm.submitting
                                                  }
                                                />
                                              </div>
                                              <div className="col-auto">
                                                <RAFButtonComponent
                                                  action={
                                                    RAFButtonConstant.Cancel
                                                  }
                                                  onClick={props.onClose}
                                                  idString="EditContent"
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </RAFForm>
                                  );
                                } else {
                                  if (error === Constants.RAFObject_NoContent) {
                                    return (
                                      <div className="container-fluid px-0">
                                        <RAFDeletedRecordState title="This item is either deleted or You do not have sufficient privileges to view this item." />
                                      </div>
                                    );
                                  } else {
                                    return (
                                      <div
                                        className="container-fluid px-0"
                                        style={{ background: "transparent" }}
                                      >
                                        {/* <ACLoadingPanel loadingText="Loading..." /> */}
                                      </div>
                                    );
                                  }
                                }
                              }}
                            </RAFObjectContext.Consumer>
                          </RAFObjectContextProvider>
                        );
                      }}
                    </RAFEntityContext.Consumer>
                  );
                }}
              </RAFAttributesContext.Consumer>
            </RAFAttributeRelatedListProvider>
          </RAFEntityProvider>
        </div>
      );
    }
  } else {
    return (
      <div
        className="container-fluid px-0"
        style={{ background: "transparent" }}
      ></div>
    );
  }
}
export default UpdateCQIRegister;
