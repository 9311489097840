import React, { PropsWithChildren, Reducer, useEffect, useReducer } from "react";
import RAFEmptyState from "../../../../RAFComponents/Navigation/RAFEmptyState";
import {
  isNotEmptyArray
} from "../../../../RAFComponents/helpers/utils";
import { AuditGroupRow, AuditRow } from "../../../../RAFComponents/models/Common/AuditRow";
import { QueryAttributeJM } from "../../../../RAFComponents/models/Common/QueryAttributeJM";
import ACLoadingPanel from "../../../../components/shared/ACLoadingPanel";
import { CareEsioEntity } from "../../../../constants/CareESIO/CareEsioConstants";
import { getAllAuditRelatedTypeAndField, getAuditGroupDetailsList, getAuditList } from "../CareAuditHelper";
import CareAuditContent from "./CareAuditContent";

interface IProps {
  relatedToUID?: string;
  primaryRelatedToUID?: string;
  isSubItems?: boolean;
  auditGroupRow?: AuditGroupRow;
}

interface IState {
  careAuditItems: AuditRow[];
  isLoading: boolean;
  // distinctEntityAndFileds: { RelatedToType: string; Fields: QueryAttributeJM[]; Entity: EntityRow; }[];
  distinctRelatedFiled: {
    RelatedToTypeUID: string;
    Fields: QueryAttributeJM[];
  }[];
}

function CareAuditList({
  relatedToUID,
  primaryRelatedToUID,
  ...props
}: PropsWithChildren<IProps>) {
  const [state, setState] = useReducer<Reducer<IState, Partial<IState>>>(
    (state, newState) => ({ ...state, ...newState }),
    {
      isLoading: true,
      careAuditItems: [],
      distinctRelatedFiled: null,
    }
  );

  useEffect(() => {
    fetchAuditItems();
  }, [relatedToUID, primaryRelatedToUID]);

  const fetchAuditItems = async () => {
    const careAuditItems: AuditRow[] = props.isSubItems ? await getAuditGroupDetailsList(props.auditGroupRow, primaryRelatedToUID) : await getAuditList(
      relatedToUID,
      primaryRelatedToUID
    );

    const distinctRelatedToTypes = isNotEmptyArray(careAuditItems) ? Array.from(new Set(careAuditItems.map(item => item.RelatedToTypeUID))) : [];

    const distinctRelatedFiled = await getAllAuditRelatedTypeAndField(distinctRelatedToTypes);

    //const distinctEntityAndFileds = await getAllEntityAndFiledsByRelatedToType(distinctRelatedToTypes);
    setState({ careAuditItems, distinctRelatedFiled, isLoading: false });
  };

  if (state.isLoading === false) {
    if (isNotEmptyArray(state.careAuditItems)) {
      return (
        <div>
          {state.careAuditItems.map((item, index) => {
            const relatedField = isNotEmptyArray(state.distinctRelatedFiled) ? state.distinctRelatedFiled.find(x => x.RelatedToTypeUID === item.RelatedToTypeUID) : null;
            return (
              <CareAuditContent key={index} relatedField={relatedField} auditRow={item} />
            );
          })}
        </div>
      );
    } else {
      return (
        <div className="mt-3">
          <RAFEmptyState
            title={`No ${CareEsioEntity.CareRecipientAuditTimeLine.DisplayName}`}
            //body={`Please click on add button to add ${CareEsioEntity.CareRecipientAuditTimeLine.DisplayName}.`}
            displayStyle="TextOnly"
          />
        </div>
      );
    }
  } else {
    return (
      <div>
        <ACLoadingPanel loadingText="Loading..." />
      </div>
    );
  }
}

export default React.memo(CareAuditList);
