import { ContentType } from "../../../constants/Common/Constants";
import {
  isNotEmptyArray,
  isNotNullAndUndefined,
  isNullOrUndefined,
  propertyOf,
  setZeroHours,
} from "../../../RAFComponents/helpers/utils";
import * as repositoryActions from "../../../RAFComponents/store/actions/repositoryActions";

import { getAllFieldsByEntityUID } from "../../../RAFComponents/helpers/AppHelper";
import { AuditGroupRow, AuditRow } from "../../../RAFComponents/models/Common/AuditRow";
import { AuditListRequest } from "../../../RAFComponents/models/Common/ListRequest";
import { RAFCustomFilter, RAFCustomOperator } from "../../../RAFComponents/RAFViewPanels/RAFFilterColumn/RAFCustomFilter";

export const getAuditList = (
  relatedToUID?: string,
  primaryRelatedToUID?: string
) => {
  return new Promise<AuditRow[]>((resolve, reject) => {
    let listServiceRequest = new AuditListRequest();
    listServiceRequest.Skip = 0;
    listServiceRequest.Take = 0;

    listServiceRequest.RelatedToUID = relatedToUID;
    listServiceRequest.PrimaryRelatedToUID = primaryRelatedToUID;

    let sortBy = {
      field: "LastActivityDate",
      order: "descending",
    };
    let sortQuery: string[] = [];
    sortQuery.push(
      sortBy.order === "descending" ? `${sortBy.field} desc` : sortBy.field
    );

    listServiceRequest.Sort = sortQuery;

    repositoryActions
      .postDataAndGetResponse(
        "Audit/List",
        listServiceRequest,
        null,
        ContentType.applicationJson
      )
      .then((response) => {
        if (isNotNullAndUndefined(response)) {
          if (response.status === 204) {
            resolve(null);
          } else if (isNotNullAndUndefined(response.data)) {
            if (isNotNullAndUndefined(response.data.Entity)) {
              resolve(response.data.Entity);
            } else if (isNotEmptyArray(response.data.ResultTable)) {
              resolve(response.data.ResultTable);
            } else if (isNotNullAndUndefined(response.data.Entities)) {
              resolve(response.data.Entities);
            } else {
              resolve(null);
            }
          } else {
            resolve(null);
          }
        }
      })
      .catch((error) => error);
  });
};

export const getAuditGroupDetailsList = (
  auditGroupRow: AuditGroupRow,
  primaryRelatedToUID?: string
) => {
  return new Promise<AuditRow[]>((resolve, reject) => {
    if (isNotNullAndUndefined(auditGroupRow) && isNotNullAndUndefined(auditGroupRow.CreatedDate)) {
      let listServiceRequest = new AuditListRequest();
      listServiceRequest.Skip = 0;
      listServiceRequest.Take = 0;

      listServiceRequest.PrimaryRelatedToUID = primaryRelatedToUID;

      let sortBy = {
        field: "LastActivityDate",
        order: "descending",
      };
      let sortQuery: string[] = [];
      sortQuery.push(
        sortBy.order === "descending" ? `${sortBy.field} desc` : sortBy.field
      );

      //listServiceRequest.Sort = sortQuery;

      let relatedFilter: RAFCustomFilter = {};
      relatedFilter.Condition = "and";
      relatedFilter.Rules = [];

      if (isNotNullAndUndefined(auditGroupRow.AuditType)) {
        let filter: RAFCustomFilter = {};
        let filterVal: string[] = [];
        filterVal.push(auditGroupRow.AuditType);
        filter.Operator = RAFCustomOperator.Equal;
        filter.Value = filterVal;
        filter.Field = propertyOf<AuditRow>("AuditType");
        relatedFilter.Rules.push(filter);
      }
      if (isNotNullAndUndefined(auditGroupRow.RelatedToType)) {
        let filter: RAFCustomFilter = {};
        let filterVal: string[] = [];
        filterVal.push(auditGroupRow.RelatedToType);
        filter.Operator = RAFCustomOperator.Equal;
        filter.Value = filterVal;
        filter.Field = propertyOf<AuditRow>("RelatedToType");
        relatedFilter.Rules.push(filter);
      }
      if (isNotNullAndUndefined(auditGroupRow.CreatedDate)) {
        const currentDate = new Date(auditGroupRow.CreatedDate);

        // let filter: RAFCustomFilter = {};
        // filter.Operator = RAFCustomOperator.Equal;
        // filter.Value = [currentDate as any];
        // filter.Field = propertyOf<AuditRow>("CreatedDate");
        // relatedFilter.Rules.push(filter);

        // const yesterday = new Date(currentDate);
        // yesterday.setDate(yesterday.getDate() - 1);

        const tomorrow = new Date(currentDate);
        tomorrow.setDate(tomorrow.getDate() + 1);

        let filterYesterday: RAFCustomFilter = {};
        filterYesterday.Operator = RAFCustomOperator.GreaterThanOrEqual;
        filterYesterday.Value = [setZeroHours(currentDate) as any];
        filterYesterday.Field = propertyOf<AuditRow>("CreatedDate");
        relatedFilter.Rules.push(filterYesterday);

        let filterTomorrow: RAFCustomFilter = {};
        filterTomorrow.Operator = RAFCustomOperator.LesserThan;
        filterTomorrow.Value = [setZeroHours(tomorrow) as any];
        filterTomorrow.Field = propertyOf<AuditRow>("CreatedDate");
        relatedFilter.Rules.push(filterTomorrow);
      }

      if (isNotNullAndUndefined(auditGroupRow.PerformedBy)) {
        let filter: RAFCustomFilter = {};
        let filterVal: string[] = [];
        filterVal.push(auditGroupRow.PerformedBy);
        filter.Operator = RAFCustomOperator.Equal;
        filter.Value = filterVal;
        filter.Field = propertyOf<AuditRow>("PerformedBy");
        relatedFilter.Rules.push(filter);
      }

      listServiceRequest.CustomFilter = relatedFilter;

      repositoryActions
        .postDataAndGetResponse(
          "Audit/List",
          listServiceRequest,
          null,
          ContentType.applicationJson
        )
        .then((response) => {
          if (isNotNullAndUndefined(response)) {
            if (response.status === 204) {
              resolve(null);
            } else if (isNotNullAndUndefined(response.data)) {
              if (isNotNullAndUndefined(response.data.Entity)) {
                resolve(response.data.Entity);
              } else if (isNotEmptyArray(response.data.ResultTable)) {
                resolve(response.data.ResultTable);
              } else if (isNotNullAndUndefined(response.data.Entities)) {
                resolve(response.data.Entities);
              } else {
                resolve(null);
              }
            } else {
              resolve(null);
            }
          }
        })
        .catch((error) => error);
    } else {
      resolve(null);
    }
  });
};

export const getAuditListGroup = (
  relatedToUID?: string,
  primaryRelatedToUID?: string
) => {
  return new Promise<AuditGroupRow[]>((resolve, reject) => {
    let listServiceRequest = new AuditListRequest();
    listServiceRequest.Skip = 0;
    listServiceRequest.Take = 0;

    listServiceRequest.RelatedToUID = relatedToUID;
    listServiceRequest.PrimaryRelatedToUID = primaryRelatedToUID;

    repositoryActions
      .postDataAndGetResponse(
        "Audit/ListGroup",
        listServiceRequest,
        null,
        ContentType.applicationJson
      )
      .then((response) => {
        if (isNotNullAndUndefined(response)) {
          if (response.status === 204) {
            resolve(null);
          } else if (isNotNullAndUndefined(response.data)) {
            if (isNotNullAndUndefined(response.data.Entity)) {
              resolve(response.data.Entity);
            } else if (isNotEmptyArray(response.data.ResultTable)) {
              resolve(response.data.ResultTable);
            } else if (isNotNullAndUndefined(response.data.Entities)) {
              resolve(response.data.Entities);
            } else {
              resolve(null);
            }
          } else {
            resolve(null);
          }
        } else {
          resolve(null);
        }
      })
      .catch((error) => error);
  });
};

export const getAllAuditRelatedTypeAndField = async (
  distinctRelatedToTypes: string[]
) => {
  const promises = distinctRelatedToTypes.map((RelatedToTypeUID) =>
    getAllFieldsByEntityUID(RelatedToTypeUID).then((Fields) => ({
      RelatedToTypeUID,
      Fields,
    }))
  );

  const results = isNotEmptyArray(distinctRelatedToTypes)
    ? await Promise.all(promises)
    : null;
  return results;
};

// const getAllEntityAndFiledsByRelatedToType = async (distinctRelatedToTypes) => {
//   const promises = distinctRelatedToTypes.map(async (RelatedToType) => {
//     const Fields = await getFields(RelatedToType);
//     const Entity = await getEntityByObjectName({ ObjectName: RelatedToType });
//     return { RelatedToType, Fields, Entity };
//   });

//   const results = await Promise.all(promises);
//   return results;
// };

export const getAuditActionMessage = (
  auditType: string,
) => {
  let retVal = "";
  if (isNullOrUndefined(auditType)) return retVal;
  switch (auditType) {
    case "Create":
      retVal = "added";
      break;
    case "Update":
      // retVal = isNotNullAndUndefined(relatedTo)
      //   ? ` updated a ${relatedToType} in `
      //   : ` updated a ${relatedToType}`;
      // retVal = isNotNullAndUndefined(relatedTo)
      //     ? ` updated in `
      //     : ` updated `;
      retVal = "modified";
      break;
    default:
      retVal = auditType;
  }
  return retVal;
};
