import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import * as React from "react";
import { PropsWithChildren, useContext } from "react";
import { Field, FormRenderProps } from "react-final-form";
import { useNavigate } from "react-router-dom";
import { PreventFocusOnDialogOpen } from "../../../RAFComponents/Dialog/SFDialogUtils";
import RAFFieldLabel from "../../../RAFComponents/Inputs/RAFFieldLabel";
import RAFForm, {
  RAFFieldError,
  composeValidators,
} from "../../../RAFComponents/Inputs/RAFForm";
import {
  RAFDefaultFieldProps,
  RAFFieldProps,
  RAFFormContext,
  isRequired,
} from "../../../RAFComponents/Inputs/RFFUtils";
import RAFButtonComponent from "../../../RAFComponents/Navigation/RAFButtonComponent";
import RAFAttributeRelatedListProvider, {
  RAFAttributesContext,
} from "../../../RAFComponents/Providers/RAFAttributeRelatedListProvider";
import { showSuccessToast, showWarningToast } from "../../../RAFComponents/Utility/RAFToastComponent";
import {
  RetrieveRecord,
  hideProgress,
  showProgress,
} from "../../../RAFComponents/helpers/AppHelper";
import {
  ConvertToURLValue,
  getSaveRequest,
  isNotNullAndUndefined,
} from "../../../RAFComponents/helpers/utils";
import { QueryAttributeJM } from "../../../RAFComponents/models/Common/QueryAttributeJM";
import * as repositoryActions from "../../../RAFComponents/store/actions/repositoryActions";
import { CareEsioEntity } from "../../../constants/CareESIO/CareEsioConstants";
import {
  ContentType,
  RAFButtonConstant,
  RAFLayout,
} from "../../../constants/Common/Constants";
import RAFEntityProvider from "../../Common/Providers/RAFEntityProvider";
import { RAFObjectContext } from "../../Common/Providers/RAFObjectContextProvider";
import RAFPageLayoutProvider from "../../Common/Providers/RAFPageLayoutProvider";
import RAFPageLayout from "../../Common/RAFPageLayout";
import UpdateCQIRegister from "./UpdateCQIRegister";

//const RAFTextBox = ({ field, label, required }) => {

interface IState {
  showLinkFormContent: boolean;
  showRelatedFormContent: boolean;
  initialValues: any;
  selectedCQIRegister: { ObjectName: string; ObjectId: string; };
}

interface IProps {
  moduleName?: string;
  entityName?: string;
}

function CQIRegisterCreateComponent<T>({
  field,
  label,
  children,
  labelClassName,
  description,
  moduleName,
  entityName,
  required = RAFDefaultFieldProps.required,
  showLabel = RAFDefaultFieldProps.showLabel,
  disabled = RAFDefaultFieldProps.disabled,
  showClearButton = RAFDefaultFieldProps.showClearButton,
  validate = RAFDefaultFieldProps.validate,
  ...props
}: PropsWithChildren<RAFFieldProps<T> & IProps>) {
  const rafFormContextValue: FormRenderProps = useContext(RAFFormContext);
  const rafObject = useContext(RAFObjectContext);

  const [state, setState] = React.useReducer<
    React.Reducer<IState, Partial<IState>>
  >((state, newState) => ({ ...state, ...newState }), {
    showLinkFormContent: false,
    initialValues: {},
    selectedCQIRegister: { ObjectName: null, ObjectId: null },
    showRelatedFormContent: false,
  });
  React.useEffect(() => {
    getRelatedValue();
  }, [field.toString()]);

  const getRelatedValue = () => {
    const currentObject = isNotNullAndUndefined(rafObject)
      ? rafObject.rafObject
      : null;
    if (isNotNullAndUndefined(currentObject)) {
      setState({
        selectedCQIRegister: {
          ObjectName: currentObject[field.toString()],
          ObjectId: currentObject[field.toString() + "UID"],
        },
      });
    }
  };

  const outerDivId = `raf_dlg_Create_Div_${CareEsioEntity.CareRecipientCQIRegister.EntityName}`;
  let rafForm: FormRenderProps | null;
  let mainFormValue = rafFormContextValue.values;

  const onSubmitObject = (
    values?: any,
    queryAttributes?: QueryAttributeJM[]
  ) => {
    let progressDiv = showProgress(`#${outerDivId}`);

    submitMainModule().then((mainModuleFormResponse) => {
      values["ShiftLogUID"] = mainModuleFormResponse.ShiftLogId;
      values["ShiftLog"] = mainModuleFormResponse.ShiftLog;
      let saveRequestData = getSaveRequest(values, values.UID);
      //saveRequestData.EntityUID = objEntity.UID;
      //if care shift log have form post selectedFormEntityname , if not post "care_shift_log"
      saveRequestData.EntityName = isNotNullAndUndefined(moduleName)
        ? moduleName
        : CareEsioEntity.CareRecipientCQIRegister.EntityName;

      repositoryActions
        .postDataAndGetResponse(
          "Datalist/Save",
          saveRequestData,
          null,
          ContentType.applicationJson
        )
        .then((response) => {
          if (
            isNotNullAndUndefined(response) &&
            isNotNullAndUndefined(response.data) &&
            isNotNullAndUndefined(response.data.EntityId)
          ) {
            RetrieveRecord(response.data.EntityId, moduleName).then(
              (cqiRegisterResponse) => {
                hideProgress(progressDiv);
                let relatedFieldName = ConvertToURLValue(moduleName) + "Id";
                showSuccessToast("CQI Register added successfully !");
                mainFormValue["UID"] = mainModuleFormResponse.ShiftLogId;
                mainFormValue[field.toString()] =
                  cqiRegisterResponse[relatedFieldName];
                mainFormValue[field.toString() + "UID"] =
                  response.data.EntityId;
                setState({
                  showLinkFormContent: false,
                  selectedCQIRegister: {
                    ObjectName: cqiRegisterResponse["HazardId"],
                    ObjectId: response.data.EntityId,
                  },
                });
              }
            );
          } else {
            showWarningToast("Sorry something went wrong !");
            // if (isNotNullAndUndefined(props.onSave)) {
            //   props.onSave();
            // }
          }
        })
        .catch((error) => error);
    });
  };

  const linkFormContent = () => {
    if (
      isNotNullAndUndefined(state.showLinkFormContent) &&
      state.showLinkFormContent === true
    ) {
      let initialValues = state.initialValues;
      const currentObject = isNotNullAndUndefined(rafObject)
        ? rafObject.rafObject
        : null;
      if (isNotNullAndUndefined(currentObject)) {
        initialValues["CareRecipientUID"] = currentObject["UID"];
        initialValues["CareRecipient"] = currentObject["FirstName"];
        initialValues["LogDate"] = new Date();
        initialValues["Type"] = moduleName;
      }

      return (
        <RAFEntityProvider
          moduleName={CareEsioEntity.CareRecipientCQIRegister.EntityName}
        >
          <RAFAttributeRelatedListProvider
            moduleName={CareEsioEntity.CareRecipientCQIRegister.EntityName}
          >
            <RAFAttributesContext.Consumer>
              {({ queryAttributes }) => {
                return (
                  <RAFForm
                    initialValues={initialValues}
                    formRef={(g) => (rafForm = g)}
                    layout={RAFLayout.TwoColumnLayout}
                    onSubmit={(values) =>
                      onSubmitObject(values, queryAttributes)
                    }
                    className="h-100"
                  //onChange={(values) => onRAFFormOnchange(values)}
                  //decorators={[accountDecorators]}
                  //convertBeforeSave={ConvertAccountRowToAccountDto}
                  >
                    <div className="e-dlg-content-outer">
                      <div className="e-dlg-body-content">
                        <div className="mb-2">
                          <RAFEntityProvider
                            moduleName={
                              CareEsioEntity.CareRecipientCQIRegister.EntityName
                            }
                          >
                            <RAFPageLayoutProvider
                              progressDivId={`#raf_dlg_Create_Div_${CareEsioEntity.CareRecipientCQIRegister.EntityName}`}
                            //rafPageType={RAFPageType.Custom}
                            >
                              <RAFPageLayout validateFields={true} />
                            </RAFPageLayoutProvider>
                          </RAFEntityProvider>
                        </div>
                        <div className="mx-1 mt-3">
                          <RAFEntityProvider moduleName={moduleName}>
                            <RAFPageLayoutProvider
                              //prefix={"SubForm" + moduleName}
                              progressDivId={`#raf_dlg_Create_Div_${CareEsioEntity.CareRecipientCQIRegister.EntityName}`}
                            >
                              <RAFPageLayout validateFields={true} />
                            </RAFPageLayoutProvider>
                          </RAFEntityProvider>
                        </div>
                      </div>
                      <div className="e-dlg-footerContent ">
                        <div className="w-100">
                          <div className="row gx-2">
                            <div className="col-auto ms-auto">
                              <RAFButtonComponent
                                type="button"
                                isPrimary
                                action={RAFButtonConstant.Save}
                                onClick={() => rafForm && rafForm.form.submit()}
                                idString="CreateContent"
                                disabled={rafForm && rafForm.submitting}
                              />
                            </div>
                            <div className="col-auto">
                              <RAFButtonComponent
                                type="button"
                                action={RAFButtonConstant.Cancel}
                                // onClick={props.onClose}
                                onClick={linkFormDialogClose}
                                idString="CreateContent"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </RAFForm>
                );
              }}
            </RAFAttributesContext.Consumer>
          </RAFAttributeRelatedListProvider>
        </RAFEntityProvider>
      );
    } else {
      return <div></div>;
    }
  };

  const linkFormDialogOpen = () => {
    if (disabled !== true) setState({ showLinkFormContent: true });
  };

  const submitMainModule = () => {
    let saveRequestData = getSaveRequest(mainFormValue, mainFormValue.UID);
    //saveRequestData.EntityUID = objEntity.UID;
    //if care shift log have form post selectedFormEntityname , if not post "care_shift_log"
    return new Promise<{ ShiftLogId: string; ShiftLog: string; }>(
      (resolve, reject) => {
        saveRequestData.EntityName = isNotNullAndUndefined(entityName)
          ? entityName
          : CareEsioEntity.CareShiftLog.EntityName;

        repositoryActions
          .postDataAndGetResponse(
            "Datalist/SaveSubForms",
            saveRequestData,
            null,
            ContentType.applicationJson
          )
          .then((response) => {
            if (
              isNotNullAndUndefined(response) &&
              isNotNullAndUndefined(response.data) &&
              isNotNullAndUndefined(response.data.EntityId)
            ) {
              resolve({
                ShiftLogId: response.data.EntityId,
                ShiftLog: response.data.ObjectName,
              });
            } else {
              showWarningToast("Sorry something went wrong !");
              resolve({ ShiftLogId: null, ShiftLog: null });
            }
          })
          .catch((error) => error);
      }
    );
  };

  const linkFormDialogClose = () => {
    setState({ showLinkFormContent: false });
  };

  let navigate = useNavigate();

  const selectedRelatedFormDialogClose = () => {
    setState({ showRelatedFormContent: false });
  };

  const selectedRelatedDialogOpen = () => {
    setState({ showRelatedFormContent: true });
  };

  const selectedRelatedContent = () => {
    if (
      isNotNullAndUndefined(state.showRelatedFormContent) &&
      state.showRelatedFormContent === true
    ) {
      return (
        <UpdateCQIRegister
          objectUID={state.selectedCQIRegister.ObjectId}
          moduleName={moduleName}
          onSave={selectedRelatedFormDialogClose.bind(this)}
          onClose={selectedRelatedFormDialogClose.bind(this)}
          isActive
        />
      );
    } else {
      return <div></div>;
    }
  };

  return (
    <div
      className={
        isNotNullAndUndefined(props.formGroupClassName)
          ? props.formGroupClassName + " form-group"
          : "form-group"
      }
    >
      <div className="row gy-2 g-0" id={"rafdiv" + field.toString()}>
        {showLabel && showLabel === true && (
          <div className="d-flex">
            {" "}
            <RAFFieldLabel
              field={field}
              label={label}
              required={required}
              labelClassName={labelClassName}
              description={description}
            ></RAFFieldLabel>
          </div>
        )}
        <div className={"col-12"}>
          <Field
            name={field.toString()}
            {...(props.initialValue
              ? { initialValue: props.initialValue }
              : {})}
            //validate={validate === true ? (required && isRequired) : null}
            {...(props.validators
              ? {
                validate:
                  validate === true
                    ? composeValidators(
                      required === true ? isRequired : null,
                      ...props.validators
                    )
                    : null,
              }
              : {
                validate:
                  validate === true
                    ? composeValidators(required === true ? isRequired : null)
                    : null,
              })}
            allowNull
            parse={(value) => (value === "" ? null : value)}
          >
            {({ input }) => (
              <div>
                <div>
                  <div className="row align-items-center" id="attachmentDiv">
                    <div className="col-12 d-flex">
                      {isNotNullAndUndefined(
                        state.selectedCQIRegister.ObjectName
                      ) ? (
                        <span
                          className="ecllipseFirstLine"
                          style={{
                            fontSize: "0.8125rem",
                            lineHeight: "1.5625",
                            cursor: "pointer",
                            color: "rgb(0, 109, 255)",
                          }}
                          onClick={() => selectedRelatedDialogOpen()}
                        >
                          {state.selectedCQIRegister.ObjectName}
                        </span>
                      ) : (
                        <ButtonComponent
                          type="button"
                          cssClass="e-small d-none primary-custom-button d-sm-inline-block"
                          //iconCss={RAFButtonConstant.Attach.IconCss}
                          // disabled={
                          //   isNotNullAndUndefined(input.value) ? true : false
                          // }
                          onClick={() => linkFormDialogOpen()}
                          content={RAFButtonConstant.Add.DisplayName}
                        ></ButtonComponent>
                      )}
                    </div>
                  </div>

                  {props.hideRequiredMessage !== true ? (
                    <RAFFieldError name={field.toString()} />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            )}
          </Field>
        </div>
      </div>

      {state.showLinkFormContent === true && (
        <DialogComponent
          header={"Add CQI Register"}
          showCloseIcon
          visible={state.showLinkFormContent}
          cssClass={
            "rightDialog createEditForm full-height form-center-dialog dlg-new-style"
          }
          id={`raf_dlg_Create_Div_${CareEsioEntity.CareRecipientCQIRegister.EntityName}`}
          content={linkFormContent.bind(this)}
          isModal
          target="body"
          closeOnEscape={false}
          close={linkFormDialogClose.bind(this)}
          //animationSettings={animationSettings}
          zIndex={1200}
          open={PreventFocusOnDialogOpen}
        ></DialogComponent>
      )}
      {state.showRelatedFormContent === true && (
        <DialogComponent
          header={"Update CQI Register"}
          showCloseIcon
          visible={state.showRelatedFormContent}
          cssClass={
            "rightDialog createEditForm full-height form-center-dialog dlg-new-style"
          }
          id={`raf_dlg_Create_Div_${CareEsioEntity.CareRecipientCQIRegister.EntityName}`}
          content={selectedRelatedContent.bind(this)}
          isModal
          target="body"
          closeOnEscape={false}
          close={selectedRelatedFormDialogClose.bind(this)}
          //animationSettings={animationSettings}
          zIndex={1200}
          open={PreventFocusOnDialogOpen}
        ></DialogComponent>
      )}
    </div>
  );
}

export default CQIRegisterCreateComponent;
