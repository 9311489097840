import moment from "moment";
import React, { PropsWithChildren } from "react";
import RAFIconImage from "../../../../RAFComponents/Navigation/RAFIconImage";
import { convertUTCDateToLocalTimezone, isNotNullAndUndefined } from "../../../../RAFComponents/helpers/utils";
import { AuditRow } from "../../../../RAFComponents/models/Common/AuditRow";
import { QueryAttributeJM } from "../../../../RAFComponents/models/Common/QueryAttributeJM";
import { getAuditActionMessage } from "../CareAuditHelper";
import CareAuditAffectedColumnContent from "./CareAuditAffectedColumnContent";

interface IProps {
    auditRow: AuditRow;
    relatedField: {
        RelatedToTypeUID: string;
        Fields: QueryAttributeJM[];
    };
}

function CareAuditContent({ auditRow, ...props }: PropsWithChildren<IProps>) {

    if (isNotNullAndUndefined(auditRow)) {
        const affectedColumns = JSON.parse(auditRow.AffectedColumns);
        const createdDate = isNotNullAndUndefined(auditRow.CreatedDate) ? convertUTCDateToLocalTimezone(auditRow.CreatedDate) : null;
        return (
            <div
                key={auditRow.UID}
                className="activitiesItem bg-white shadow-none border-bottom d-flex"
            >
                <div className="">
                    <RAFIconImage
                        value={
                            isNotNullAndUndefined(auditRow.PerformedBy)
                                ? auditRow.PerformedBy
                                : ""
                        }
                        userIcon
                        iconSize="30"
                        fontSize="12"
                    />
                </div>
                <div className="w-100">
                    <div className="row gy-1 gx-0">
                        <div className="activitiesContentDiv d-flex align-auditRows-center flex-wrap col-12">
                            <span className="me-1 activity-title">
                                {isNotNullAndUndefined(auditRow.PerformedBy)
                                    ? auditRow.PerformedBy
                                    : ""}
                            </span>
                            <span className="mx-1 activity-action">
                                {getAuditActionMessage(
                                    auditRow.AuditType
                                )}
                            </span>
                            {isNotNullAndUndefined(auditRow.RelatedToTypeDisplayName) && (
                                <span className="mx-1 activity-action">
                                    {auditRow.RelatedToTypeDisplayName}
                                </span>
                            )}
                            {isNotNullAndUndefined(auditRow.RelatedTo) && (
                                <div className="d-flex align-auditRows-center flex-nowrap">
                                    {/* <span
                  className={"fas fa-" + iconName + " hidden"}
                  style={{ marginTop: "0.125em" }}
                ></span> */}
                                    {/* <span className="d-inline-block">
                  {this.getModuleIcon(relatedToType)}
                </span> */}
                                    <span
                                        className="ecllipseFirstLine"
                                        style={{
                                            fontSize: "0.8125rem",
                                            lineHeight: "1.5625",
                                            cursor: "pointer",
                                            color: "#006DFF",
                                        }}
                                    >
                                        {auditRow.RelatedTo}
                                    </span>
                                </div>
                            )}
                        </div>
                        <div
                            className="activity-footer d-block col-12"
                            style={{ paddingTop: "-0.3125em" }}
                            id={"summaryActivity_" + auditRow.UID}
                        >
                            <span
                                className="activity-date"
                                id="acivityRecordInfo"
                            >
                                {/* {moment(createdDate).format(
                    "ddd, MMM Do YYYY, h:mm:ss a"
                  )} */}
                                {moment(createdDate)
                                    .format("DD/MM/YYYY hh:mm A")
                                    .toString()}
                            </span>
                        </div>
                        <div className="col-12">
                            <div className="row g-2 gy-1">
                                {/* {updated column and value goes here} */}
                                {affectedColumns?.map((column: any, index) => {
                                    return (
                                        <CareAuditAffectedColumnContent
                                            key={index}
                                            relatedField={props.relatedField}
                                            auditRow={auditRow}
                                            column={column}
                                            index={index} />
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        return null;
    }
}

export default React.memo(CareAuditContent);