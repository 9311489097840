import React, { PropsWithChildren } from "react";
import RAFCustomFieldValue from "../../../../RAFComponents/Navigation/RAFCustomFieldValue";
import { isNotEmptyArray, isNotNullAndUndefined } from "../../../../RAFComponents/helpers/utils";
import { AuditRow } from "../../../../RAFComponents/models/Common/AuditRow";
import { QueryAttributeJM } from "../../../../RAFComponents/models/Common/QueryAttributeJM";

interface IProps {
    auditRow: AuditRow;
    column: any;
    index: number;
    relatedField: {
        RelatedToTypeUID: string;
        Fields: QueryAttributeJM[];
    };
};

function CareAuditAffectedColumnContent({ auditRow, column, index, ...props }: PropsWithChildren<IProps>) {

    if (isNotNullAndUndefined(auditRow) && isNotNullAndUndefined(column)) {
        const allFields = isNotNullAndUndefined(props.relatedField) ? props.relatedField.Fields : [];
        const queryAttribute = isNotEmptyArray(allFields) ? allFields.find(x => x.PropertyName === column) : null;

        const oldValues = JSON.parse(auditRow.OldValues);
        const newValues = JSON.parse(auditRow.NewValues);

        if (column === "TagsListJson") return null;
        let oldValue = oldValues[column];
        let newValue = newValues[column];

        const fieldLabel = isNotNullAndUndefined(queryAttribute) ? queryAttribute.DisplayName : column;
        if (isNotNullAndUndefined(oldValue) || isNotNullAndUndefined(newValue)) {
            return (
                <div className="col-12" key={index}>
                    <div className="row gx-3">
                        <div className="col-md-3">
                            <span className="details-label-light">
                                {fieldLabel}
                            </span>
                        </div>
                        <div className="col-md-4">
                            <span className="details-value">
                                <RAFCustomFieldValue fieldValue={oldValue} queryAttribute={queryAttribute} />
                            </span>
                        </div>
                        <div className={"col-md-5"}>
                            <span className="details-value">
                                <RAFCustomFieldValue fieldValue={newValue} queryAttribute={queryAttribute} />
                            </span>
                        </div>

                    </div>
                    {/* <span style={{ fontWeight: 600 }}>
                        {fieldLabel + " : "}
                    </span>
                    {isNotNullAndUndefined(oldValue) && (
                        <RAFCustomFieldValue fieldValue={oldValue} queryAttribute={queryAttribute} />
                    )}
                    <span
                        className="mx-1"
                        style={{ fontWeight: 600 }}
                    >
                        {'->'}
                    </span>
                    <span>
                        <RAFCustomFieldValue fieldValue={newValue} queryAttribute={queryAttribute} />
                    </span> */}
                </div>
            );
        } else {
            return null;
        }
    } else {
        return null;
    }
}

export default React.memo(CareAuditAffectedColumnContent);